.country {
    display: flex;
    width: 100%;
    align-items: center;
}

.country_label {
    font-size: 15px;
    font-weight: bold;
}

.country_img {
    min-width: 40px;
}

.countries_textfield .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    margin-top: 0 !important;
}

.countries_textfield .MuiFormLabel-root.MuiInputLabel-shrink {
    margin-top: -8px !important;
}
