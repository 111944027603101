.website_form {
  width: 80%;
  max-width: 700px;
  margin-top: 30px;
}

.website_form p {
  margin-bottom: 5px;
}

.website_form .center {
  text-align: center;
}

.website_form .image_picker_button {
  width: 100%;
  min-height: 100px;
}

.website_form .image_picker_button img {
  width: 100%;
}

.website_form .MuiTextField-root {
  background-color: white;
}

.website_form .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.website_form .width_row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.website_form .width_row p {
  margin: 0;
  margin-right: 10px;
}

.website_form .width_row .MuiFormControl-root input {
  width: 70px !important;
}

.website_form .integration_code_input {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.website_form .integration_code_input .copy_icon {
  cursor: pointer;
}

.website_form .save_button {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
}

@media only screen and (max-width: 649px) {
  .website_form .row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 449px) {
  .website_form {
    width: 90%;
  }
}
