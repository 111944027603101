.sidebarItem {
    display: flex;
    color: #94a7af;
    font-size: 16px;
    align-items: center;
    height: 35px;
    margin: 8px 0px;
    cursor: pointer;
}

.sidebarItem_icon {
    height: 70%;
    width: 25px;
    text-align: center;
}

.sidebarItem .sidebarItem_label {
    color: #405469;
    font-size: 14px;
    margin-left: 20px;
    white-space: nowrap;
    box-sizing: content-box;
}

.sidebarItem.disabled {
    pointer-events: none;
}

.sidebarItem.disabled .sidebarItem_label {
    color: rgb(223, 223, 223);
}

.sidebarItem.disabled .sidebarItem_icon svg path {
    fill: rgb(223, 223, 223);
}

.sidebarItem .sidebarItem_icon svg {
    height: 23px;
}

.sidebarItem .sidebarItem_icon svg path {
    fill: #94a7af;
}

.sidebarItem.active .sidebarItem_line {
    opacity: 1;
}

@media only screen and (max-width: 999px) {
    .sidebar_mini .sidebarItem_icon {
        display: flex;
        flex: 1;
        justify-content: center;
    }
}